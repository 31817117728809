import React, { useState, useRef } from "react"
import { Form } from "react-bootstrap"
import styles from "../../OrderModal.module.scss"

const DateTimeInput = ({ validated, setTime }) => {
  const dayRef = useRef(null)
  const monthRef = useRef(null)
  const yearRef = useRef(null)
  const timeRef = useRef(null)
  const [date, setDate] = useState({
    day: "",
    month: "",
    year: "",
    fullTime: "",
  })

  const handleInput = (maxLength, nextRef, value, type) => {
    if (value.length === maxLength) {
      nextRef.current.focus()
    }
    setDate(prevDate => ({ ...prevDate, [type]: value }))
  }

  const handleTimeInput = value => {
    let formattedValue = value.replace(/[^\d]/g, "")
    if (formattedValue.length >= 2) {
      formattedValue = `${formattedValue.slice(0, 2)}:${formattedValue.slice(2)}`
    }
    if (formattedValue.length >= 5) {
      formattedValue = `${formattedValue.slice(0, 5)}:${formattedValue.slice(5,7)}`
    }
    setDate(prevDate => ({ ...prevDate, fullTime: formattedValue.slice(0, 8) }))
    setTime(date)
  }

  return (
    <>
      <p className="font-weight-bold">Pick up date and time</p>
      <div className={styles.datetimeContainer}>
        <Form.Group>
          <Form.Control
            type="text"
            ref={dayRef}
            className={styles.dayInput}
            maxLength={2}
            placeholder="dd"
            required
            value={date.day}
            onChange={e => handleInput(2, monthRef, e.target.value, "day")}
            isInvalid={validated && !date.day}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            ref={monthRef}
            className={styles.monthInput}
            type="text"
            maxLength={2}
            placeholder="mm"
            required
            onChange={e => handleInput(2, yearRef, e.target.value, "month")}
            isInvalid={validated && !date.month}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            ref={yearRef}
            className={styles.yearInput}
            type="text"
            maxLength={4}
            placeholder="yyyy"
            required
            onChange={e => handleInput(4, timeRef, e.target.value, "year")}
            isInvalid={validated && !date.year}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            ref={timeRef}
            className={styles.timeInput}
            type="text"
            value={date.fullTime}
            placeholder="hh:mm:ss"
            required
            onChange={e => handleTimeInput(e.target.value)}
            isInvalid={validated && !date.fullTime}
          />
        </Form.Group>
      </div>
    </>
  )
}

export default DateTimeInput
