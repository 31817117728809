import blm from "images/merchants/blm.png"
import discountBM from "images/merchants/discountBM.png"
import medwayBM from "images/merchants/medway.jpg"
import championTimber from "images/merchants/championTimber.png"
import jewson from "images/merchants/jewson.png"
import lords from "images/merchants/lords.png"
import maidstone from "images/merchants/maidstone.png"
import selco from "images/merchants/selco.png"
import clubbSandandGravel from "images/merchants/clubbSandandGravel.jpg"
import { searchUnleashed } from 'src/merchantAPIs/unleashed';
import { searchDiscountBM } from 'src/merchantAPIs/discountBM';
import { searchMedwayBM } from 'src/merchantAPIs/medwayBM';
import { searchChampionTimberBM } from 'src/merchantAPIs/championTimberBM';
import { searchJewsonBM } from 'src/merchantAPIs/jewsonBM';
import { searchLordsBM } from 'src/merchantAPIs/lordsBM';
import { searchMaidstoneBM } from 'src/merchantAPIs/maidstoneBM';
import { searchSelcoBM } from 'src/merchantAPIs/selcoBM';
import { searchClubbSandandGravelBM } from "./clubbSandandGravelBM"

export const merchantsList: Merchant[] = [
    {
        merchantName: "BLM",
        merchantBrandPicture: blm,
        merchantEmail: "info@sortit.ai",
        merchantSites: ["BLM, Site 1;", "BLM, Site 2;", "BLM, Site 3;", "N/A"],
        searchFunction: searchUnleashed,
    },
    {
        merchantName: "Discount BM",
        merchantBrandPicture: discountBM,
        merchantEmail: "info@sortit.ai",
        merchantSites: ["Discount BM, Site 1;", "Discount BM, Site 2;", "Discount BM, Site 3;", "N/A"],
        searchFunction: searchDiscountBM,
    },
    {
        merchantName: "Medway BM",
        merchantBrandPicture: medwayBM,
        merchantEmail: "info@sortit.ai",
        merchantSites: ["Medway BM, Site 1;", "Medway BM, Site 2;", "Medway BM, Site 3;", "N/A"],
        searchFunction: searchMedwayBM,
    },
    {
        merchantName: "Champion Timber",
        merchantBrandPicture: championTimber,
        merchantEmail: "info@sortit.ai",
        merchantSites: ["Champion Timber, Site 1;", "Champion Timber, Site 2;", "Champion Timber, Site 3;", "N/A"],
        searchFunction: searchChampionTimberBM,
    },
    {
        merchantName: "Jewson",
        merchantBrandPicture: jewson,
        merchantEmail: "info@sortit.ai",
        merchantSites: ["Jewson, Site 1;", "Jewson, Site 2;", "Jewson, Site 3;", "N/A"],
        searchFunction: searchJewsonBM,
    },
    {
        merchantName: "Lords",
        merchantBrandPicture: lords,
        merchantEmail: "info@sortit.ai",
        merchantSites: ["Lords, Site 1;", "Lords, Site 2;", "Lords, Site 3;", "N/A"],
        searchFunction: searchLordsBM,
    },
    {
        merchantName: "Maidstone",
        merchantBrandPicture: maidstone,
        merchantEmail: "info@sortit.ai",
        merchantSites: ["Maidstone, Site 1;", "Maidstone, Site 2;", "Maidstone, Site 3;", "N/A"],
        searchFunction: searchMaidstoneBM,
    },
    {
        merchantName: "Selco",
        merchantBrandPicture: selco,
        merchantEmail: "info@sortit.ai",
        merchantSites: ["Selco, Site 1;", "Selco, Site 2;", "Selco, Site 3;", "N/A"],
        searchFunction: searchSelcoBM,
    },
    {
        merchantName: "Clubb Sand & Gravel",
        merchantBrandPicture: clubbSandandGravel,
        merchantEmail: "tmulton@clubbconcrete.co.uk",
        merchantSites: ["Clubb Sand & Gravel, Site 1;", "Clubb Sand & Gravel, Site 2;", "Clubb Sand & Gravel, Site 3;", "N/A"],
        searchFunction: searchClubbSandandGravelBM
    }
];