import React from "react"
import { Form } from "react-bootstrap"
import styles from "../CreateProjectModal.module.scss";
import { ProjectDurationProps } from "../../MerchantAccordionProps";

const ProjectDuration = ({ duration, setDuration, isValidationTriggered }: ProjectDurationProps) => {

  const handleDurationChange = (unit) => (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    setDuration({ ...duration, [unit]: numericValue ? `${numericValue} ${unit}` : '' });
  };

  const hasInvalidInput = !duration.days && !duration.weeks && !duration.months;

  return (
    <>
      <p className="font-weight-bold">Project duration</p>
      <div className={styles.dateContainer}>
        <Form.Group>
          <Form.Control
            type="text"
            value={duration.days}
            required
            placeholder="Days"
            className={styles.dateInput}
            onChange={handleDurationChange('days')}
            isInvalid={isValidationTriggered && hasInvalidInput}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            value={duration.weeks}
            required
            placeholder="Weeks"
            className={styles.dateInput}
            onChange={handleDurationChange('weeks')}
            isInvalid={isValidationTriggered && hasInvalidInput}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            value={duration.months}
            required
            placeholder="Months"
            className={styles.dateInput}
            onChange={handleDurationChange('months')}
            isInvalid={isValidationTriggered && hasInvalidInput}
          />
        </Form.Group>
      </div>
    </>
  );
};

export default ProjectDuration