import React, { useRef } from "react"
import Form from "react-bootstrap/Form";
import styles from "../CreateProjectModal.module.scss";

const DateTimeInput = ({ date, setDate, isValidationTriggered }) => {
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const handleInput = (maxLength, nextRef, value, type) => {
    if (value.length === maxLength && nextRef && nextRef.current) {
      nextRef.current.focus();
    }
    setDate(prevDate => ({ ...prevDate, [type]: value }));
  };

  return (
    <>
      <p className="font-weight-bold">Project Start Date</p>
      <div className={styles.dateContainer}>
        <Form.Group>
          <Form.Control
            type="text"
            ref={dayRef}
            className={styles.dateInput}
            maxLength={2}
            placeholder="DD"
            required
            value={date.day}
            onChange={e => handleInput(2, monthRef, e.target.value, "day")}
            isInvalid={isValidationTriggered && !date.day}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            ref={monthRef}
            className={styles.dateInput}
            type="text"
            maxLength={2}
            placeholder="MM"
            required
            onChange={e => handleInput(2, yearRef, e.target.value, "month")}
            isInvalid={isValidationTriggered && !date.month}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            ref={yearRef}
            className={styles.dateInput}
            type="text"
            maxLength={4}
            placeholder="YYYY"
            required
            onChange={e => handleInput(4, null, e.target.value, "year")}
            isInvalid={isValidationTriggered && !date.year}
          />
        </Form.Group>
      </div>
    </>
  );
}

export default DateTimeInput
