import React, { useState } from "react"
import { Form, Button } from "react-bootstrap"
import Dropdown from "react-bootstrap/Dropdown"
import styles from "../OrderModal.module.scss"

const PROJECT_TYPE_OPTIONS = ["New build", "Refurb", "Both", "N/A"]

const PROJECT_SCOPE_OPTIONS = ["Partial", "Total"]

const PROJECT_SUBTYPE_OPTIONS = [
  {
    label: "Carpentry (Cosmetic/ Architectural)",
    value: "Carpentry (Cosmetic/ Architectural)",
  },
  { label: "Carpentry (Structural)", value: "Carpentry (Structural)" },
  {
    label: "Blockwork (Cosmetic/ architectural)",
    value: "Blockwork (Cosmetic/ architectural)",
  },
  { label: "Blockwork (Structural)", value: "Blockwork (Structural)" },
  { label: "Civil", value: "Civil" },
  { label: "Electrical", value: "Electrical" },
  { label: "Heating", value: "Heating" },
  { label: "Landscaping", value: "Landscaping" },
  { label: "Plumbing", value: "Plumbing" },
  { label: "Structural", value: "Structural" },
]

const ProjectDetails = ({
  setActiveStep,
  resetForm,
  setCollectedInformation,
}) => {
  const [projectName, setProjectName] = useState("")
  const [clientName, setClientName] = useState("")
  const [projectAddress, setProjectAddress] = useState("")
  const [projectDescription, setProjectDescription] = useState("")
  const [projectTypeOption, setProjectTypeOption] = useState("")
  const [projectScopeOption, setProjectScopeOption] = useState("")
  const [selectedOptions, setSelectedOptions] = useState([])
  const [validated, setValidated] = useState(false)

  const handleSelect = value => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter(option => option !== value))
    } else {
      setSelectedOptions([...selectedOptions, value])
    }
  }

  const collectUserChoice = () => {
    setCollectedInformation({
      projectName,
      clientName,
      projectAddress,
      projectDescription,
      projectTypeOption,
      projectScopeOption,
      selectedOptions,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const isValid =
      event.currentTarget.checkValidity() &&
      projectName &&
      clientName &&
      projectAddress &&
      projectTypeOption &&
      projectScopeOption &&
      selectedOptions.length > 0

    setValidated(true)
    if (isValid) {
      collectUserChoice();
      setActiveStep(prev => prev + 1)
    }
  }

  return (
    <div className="d-flex flex-column">
      <Form className="d-flex flex-column" noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-6">
          <Form.Control
            type="text"
            placeholder="Project Name"
            required
            value={projectName}
            onChange={e => setProjectName(e.target.value)}
            isInvalid={validated && !projectName}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a project name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-6">
          <Form.Control
            value={projectDescription}
            onChange={e => setProjectDescription(e.target.value)}
            type="text"
            placeholder="Project Description"
          />
        </Form.Group>
        <Form.Group className="mb-6">
          <Form.Control
            type="text"
            placeholder="Client Name/Company Name"
            required
            value={clientName}
            onChange={e => setClientName(e.target.value)}
            isInvalid={validated && !clientName}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a client name or company name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-6">
          <Form.Control
            type="text"
            placeholder="Project address"
            required
            value={projectAddress}
            onChange={e => setProjectAddress(e.target.value)}
            isInvalid={validated && !projectAddress}
          />
          <Form.Control.Feedback type="invalid">
            Please enter project address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-6">
          <Dropdown onSelect={eventKey => setProjectTypeOption(eventKey)}>
            <Dropdown.Toggle
              variant="outline-secondary"
              className={`${styles.dropdown} ${
                validated && !projectTypeOption ? "is-invalid" : ""
              }`}
            >
              {projectTypeOption || "Project Type"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {PROJECT_TYPE_OPTIONS.map(option => (
                <Dropdown.Item key={option} eventKey={option}>
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {validated && !projectTypeOption && (
            <div className="invalid-feedback d-block">
              Please select a project type.
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-6">
          <Dropdown>
            <Dropdown.Toggle
              className={`${styles.dropdown} ${
                validated && selectedOptions.length === 0 ? "is-invalid" : ""
              }`}
              variant="outline-secondary"
            >
              <span>Project Subtypes</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100 d-flex flex-column align-items-start p-3">
              {PROJECT_SUBTYPE_OPTIONS.map(({ label, value }) => (
                <Form.Check
                  key={value}
                  type="checkbox"
                  className="mb-2"
                  label={label}
                  value={value}
                  checked={selectedOptions.includes(value)}
                  onChange={() => handleSelect(value)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {validated && selectedOptions.length === 0 && (
            <div className="invalid-feedback d-block">
              Please select at least one project subtype.
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-6">
          <Dropdown onSelect={eventKey => setProjectScopeOption(eventKey)}>
            <Dropdown.Toggle
              variant="outline-secondary"
              className={`${styles.dropdown} ${
                validated && !projectScopeOption ? "is-invalid" : ""
              }`}
            >
              {projectScopeOption || "Project Scope"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {PROJECT_SCOPE_OPTIONS.map(option => (
                <Dropdown.Item key={option} eventKey={option}>
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {validated && !projectScopeOption && (
            <div className="invalid-feedback d-block">
              Please select a project scope.
            </div>
          )}
        </Form.Group>
        <div className={styles.footerContainer}>
          <Button variant="secondary" onClick={resetForm}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ProjectDetails
