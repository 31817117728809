import React, { useState } from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import { CollectedOrderInformation, OrderFormsWrapperProps } from "../../MerchantAccordionProps"
import ProjectDetails from "../ProjectDetails"
import Order from "../Order"
import OrderDetails from "../OrderDetails"

const defaultOrderInformationState = {
  projectName: "",
  clientName: "",
  projectAddress: "",
  projectDescription: "",
  projectTypeOption: "",
  projectScopeOption: "",
  selectedOptions: []
}

const OrderFormsWrapper = ({ orderModalConfiguration, setOrderModalOpen }: OrderFormsWrapperProps) => {
  const steps = ["Project Details", "Order Details", "Order"]
  const [activeStep, setActiveStep] = useState(0);
  const [collectedInformation, setCollectedInformation] = useState<CollectedOrderInformation>(defaultOrderInformationState);

  const resetForm = () => {
    setActiveStep(0);
    setOrderModalOpen(false);
  }

  const MODALS = {
    0: <ProjectDetails setActiveStep={setActiveStep} resetForm={resetForm} setCollectedInformation={setCollectedInformation} />,
    1: <OrderDetails projectAddress={collectedInformation.projectAddress} merchantSites={orderModalConfiguration.orderConfiguration.merchantSites} setActiveStep={setActiveStep} resetForm={resetForm} />,
    2: <Order {...orderModalConfiguration} resetForm={resetForm} />,
  }
  return (
    <>
      <Box sx={{ width: "100%", marginBottom: "2rem" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {MODALS[activeStep]}
    </>
  )
}

export default OrderFormsWrapper
