import React, { useContext, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import { SaveProjectModalProps } from "../MerchantAccordionProps"
import { AuthContext } from "src/context/AuthContext"

const SaveProjectModal = ({
  isSaveProjectModalOpen,
  setSaveProjectModalOpen,
}: SaveProjectModalProps) => {
  const [validated, setValidated] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectType, setProjectType] = useState("");
  const [clientName, setClientName] = useState("");
  const [startDate, setStartDate] = useState("");
  const { user: { id, nickname } } = useContext(AuthContext);

  const clearForm = () => {
    setProjectName("")
    setProjectType("")
    setClientName("")
    setStartDate("")
  }

  const closeModal = () => {
    setSaveProjectModalOpen(false)
    clearForm()
    setValidated(false)
  }

  const formatDateInput = ({ target: { value } }) => {
    let formattedValue = value.replace(/[^\d]/g, "")

    formattedValue =
      formattedValue.length >= 5
        ? `${formattedValue.slice(0, 2)}/${formattedValue.slice(
            2,
            4
          )}/${formattedValue.slice(4)}`
        : formattedValue.length >= 2
        ? `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`
        : formattedValue

    setStartDate(formattedValue)
  }

  const checkValidation = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
  }

  const handleSubmit = (event) => {
    checkValidation(event);
  }

  return (
    <Modal show={isSaveProjectModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Save Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="d-flex flex-column"
          noValidate
          validated={validated}
        >
          <Form.Group className="mb-4" controlId="project-name-control-id">
            <Form.Label className="font-weight-bold">Project name *</Form.Label>
            <Form.Control
              required
              value={projectName}
              type="text"
              onChange={({ target: { value } }) => setProjectName(value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a project name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4" controlId="project-type-control-id">
            <Form.Label className="font-weight-bold">Project type *</Form.Label>
            <Form.Control
              required
              value={projectType}
              type="text"
              onChange={({ target: { value } }) => setProjectType(value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a project type.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4" controlId="project-client-name-control-id">
            <Form.Label className="font-weight-bold">
              Client's name *
            </Form.Label>
            <Form.Control
              required
              value={clientName}
              type="text"
              onChange={({ target: { value } }) => setClientName(value)}
            />
            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide client's name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4 d-flex flex-column">
            <Form.Label className="font-weight-bold">BoM Link</Form.Label>
            <span className="text-secondary">
              https://app.sortit.ai/{nickname}/projects/{id}
            </span>
          </Form.Group>
        </Form>
        <Form.Group className="mb-4" controlId="project-start-date-input">
            <Form.Label className="font-weight-bold">
              Start date (DD/MM/YYYY)
            </Form.Label>
            <Form.Control
              value={startDate}
              type="text"
              onChange={formatDateInput}
              maxLength={10}
            />
          </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SaveProjectModal
