import { getCheapestItem, getFilteredItems } from 'src/utils/parseBMJsonFile';
import clubbSandandGravelBMData from 'src/content/clubbSandandGravel.json';

export const searchClubbSandandGravelBM: ApiSearchFunc = async (data: UnleashedSearchProps): Promise<ApiSearchResults> => {
    const { material, quantity, manufacturer, variation } = data;
    const response = await clubbSandandGravelBMData.ClubbSandandGravel;
    const filteredItems: APISearchResult[] = getFilteredItems(response, material, manufacturer, quantity, variation);
    const cheapestItem: APISearchResult = getCheapestItem(filteredItems, material);
    const finalResult: ApiSearchResults = {
        material,
        cheapestItem,
        results: filteredItems,
    }
    return finalResult;
}