import React, { useContext } from "react"
import Modal from "react-bootstrap/Modal"
import { AuthContextInterface } from "src/context"
import { AuthContext } from "src/context/AuthContext"
import { OrderModalProps } from "../MerchantAccordionProps"
import { ToastContainer } from "react-toastify"
import OrderFormsWrapper from "./OrderFormsWrapper"

const OrderModal = ({ isOrderModalOpen, setOrderModalOpen, orderConfiguration }: OrderModalProps) => {
  const { currentUserConfiguration: { isAbleToOrder, orderMessage } }: AuthContextInterface = useContext(AuthContext);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
      />
      <Modal show={isOrderModalOpen} onHide={() => setOrderModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isAbleToOrder ? (
              <>Order placement for {orderConfiguration.merchantName}</>
            ) : (
              <>Order Notification</>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAbleToOrder ? (
            <OrderFormsWrapper
              setOrderModalOpen={setOrderModalOpen}
              orderModalConfiguration={{
                orderConfiguration,
              }}
            />
          ) : (
            <>{orderMessage}</>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
export default OrderModal
