import React from "react"
import { Dropdown, Form } from "react-bootstrap"
import DateTimeInput from "../DateTimeInput"
import styles from "../../OrderModal.module.scss";
import { WithoutDeliveryProps } from "../../../MerchantAccordionProps";

const WithoutDelivery = ({ merchantSites, setPickUpDateAndTime, validated, merchantSite, setMerchantSite }: WithoutDeliveryProps) => {
  return (
    <div className="mt-3">
      <DateTimeInput validated={validated} setTime={setPickUpDateAndTime} />
      <Form.Group className="mt-4">
          <Dropdown onSelect={eventKey => setMerchantSite(eventKey)}>
            <Dropdown.Toggle
              variant="outline-secondary"
              className={`${styles.dropdown} ${
                validated && !merchantSite ? "is-invalid" : ""
              }`}
            >
              {merchantSite || "Merchant Site"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {merchantSites.map(option => (
                <Dropdown.Item key={option} eventKey={option}>
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {validated && !merchantSite && (
            <div className="invalid-feedback d-block">
              Please select a project type.
            </div>
          )}
        </Form.Group>
        <p className="font-weight-bold">Pick up notes:</p>
        <Form.Group>
        <Form.Control placeholder="Add any additional that could be helpful here" as="textarea" rows={5} />
        </Form.Group>
    </div>
  )
}

export default WithoutDelivery
