import React from "react"
import Form from "react-bootstrap/Form";
import { FormInputProps } from "../../MerchantAccordionProps";

const FormInput = ({ value, setter, placeholder, isRequired, isValidationTriggered, errorMessage }: FormInputProps) => {
  return (
    <Form.Group className="mb-6">
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={value}
        required={isRequired}
        onChange={({ currentTarget: { value } }) => setter(value)}
        isInvalid={isValidationTriggered && !value}
      />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default FormInput
