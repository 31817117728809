import React, { useState } from "react"
import { Form, Button } from "react-bootstrap"
import WithoutDelivery from "./WithoutDelivery"
import styles from "../OrderModal.module.scss"
import { OrderDetailsProps } from "../../MerchantAccordionProps"
import WithDelivery from "./WithDelivery"

const OrderDetails = ({ projectAddress, merchantSites, setActiveStep, resetForm }: OrderDetailsProps) => {
  const [isDeliveryRequired, setDeliveryRequired] = useState(1)
  const [orderRecipientName, setOrderRecipientName] = useState("")
  const [orderRecipientAddress, setOrderRecipientAdress] = useState("")
  const [deliveryDateAndTime, setDeliveryDateAndTime] = useState("")
  const [merchantSite, setMerchantSite] = useState("")
  const [pickUpDateAndTime, setPickUpDateAndTime] = useState("")
  const [validated, setValidated] = useState(false)
  const [isAddressSame, setAddressSame] = useState(null)
 
  const deliveryBody = {
    0: (
      <WithoutDelivery
        merchantSites={merchantSites}
        validated={validated}
        setPickUpDateAndTime={setPickUpDateAndTime}
        merchantSite={merchantSite}
        setMerchantSite={setMerchantSite}
      />
    ),
    1: <WithDelivery 
        validated={validated}
        isAddressSame={isAddressSame}
        setAddressSame={setAddressSame}
        projectAddress={projectAddress}
        orderRecipientAddress={orderRecipientAddress}
        setOrderRecipientAdress={setOrderRecipientAdress}
        setDeliveryDateAndTime={setDeliveryDateAndTime}
        />,
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (isDeliveryRequired) {
        const isValid = event.currentTarget.checkValidity() && orderRecipientName && (isAddressSame ? projectAddress : orderRecipientAddress) && deliveryDateAndTime  
        setValidated(true)
        if (isValid) {
          setActiveStep((prev: number) => prev + 1)
        }
    } else {
      const isValid = event.currentTarget.checkValidity() && orderRecipientName && merchantSite && pickUpDateAndTime
      setValidated(true)
      if (isValid) {
        setActiveStep((prev: number) => prev + 1)
      }
    }
  }

  return (
    <div className="d-flex flex-column">
      <Form className="d-flex flex-column" noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-6">
          <Form.Control
            type="text"
            placeholder="Order Recipient Name"
            required
            onChange={({ currentTarget: { value } }) =>
              setOrderRecipientName(value)
            }
            isInvalid={validated && !orderRecipientName}
          />
          <Form.Control.Feedback type="invalid">
            Please enter an order recipient name.
          </Form.Control.Feedback>
        </Form.Group>
        <div>
          <span className="font-weight-bold">Is delivery required?</span>
          <div className={styles.deliveryContainer}>
            <div className={styles.deliveryCheckbox}>
              <label>Yes</label>
              <input
                type="checkbox"
                checked={isDeliveryRequired === 1}
                onChange={() => setDeliveryRequired(1)}
              />
            </div>
            <div className={styles.deliveryCheckbox}>
              <label>No</label>
              <input
                type="checkbox"
                checked={isDeliveryRequired === 0}
                onChange={() => setDeliveryRequired(0)}
              />
            </div>
          </div>
          <div>{deliveryBody[isDeliveryRequired]}</div>
        </div>
        <div className={styles.footerContainer}>
          <Button
            variant="secondary"
            onClick={resetForm}
          >
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Create Order
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default OrderDetails
