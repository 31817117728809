import React, { useContext, useEffect, useState } from "react"
import { CSVLink } from "react-csv";
import { Col, Toast, Row, Button, Form, Alert } from "react-bootstrap";
import { AuthContext } from "src/context/AuthContext";
import { OrderProps } from "../../MerchantAccordionProps";
import styles from "../OrderModal.module.scss";
import { toast } from "react-toastify"

const Order = ({ orderConfiguration: { merchantName, merchantEmail, cheapestItems }, resetForm}: OrderProps) => {

  const csvHeadings = [ "Merchant", "Item", "Description", "Qty", "Cost", "Total", "Merchant Total", ""];
  const [csvFilename, setCsvFilename] = useState("");
  const [subject, setSubject] = useState("");
  const [isTermsAndConditionOpen, setTermsAndConditionOpen] = useState(false);
  const [validationError, setValidationError] = useState(false)
  const [isChecked, setChecked] = useState(false)

  const SORT_IT_EMAIL = "info@sortit.ai";

  const { user: { email, name } } = useContext(AuthContext);

  const formatFilename = () => {
    const date = new Date().toISOString().split('T')[0];
    const time = new Date().toTimeString().split(' ')[0].replace(/:/g, '\uA789');
    const customerInitials = name.split(" ").map((n)=>n[0]).join("_");
    return `${date}_${time}_SIAI_${merchantName}-1-${customerInitials}-Quote`
  };

  const buildDataForCSV = (data) => {
    return data.map(({ material, description, quantity, cost, total }, index, arr) => [
            merchantName,
            material,
            description,
            quantity.toString(),
            cost.toString(),
            total.toString(),
            ...(index === arr.length - 1 ? [`${merchantName} Total`, arr.reduce((acc, { total }) => Number(acc) + Number(total), 0).toFixed(2)] : [])
        ])
  }

  useEffect(() => {
    const date = new Date().toISOString().split('T')[0];
    const time = new Date().toTimeString().substring(0, 8).replace(/:/g, '-');
    const projectName = "Breezehurst Farm".match(/[^aeiou\s]/gi).slice(0, 3).join('').toUpperCase();
    const merchant = merchantName.match(/[^aeiou\s]/gi).slice(0, 3).join('').toUpperCase();
    const numberOfOrders = cheapestItems.length;
    setSubject(`Order from SORT IT AI: ${date}_${time}_${projectName}_SIAI_C12_${merchant}_BM_W_000${numberOfOrders}_V1.00`)
  }, [])

  const customerMessage = `
    Dear ${merchantName}.

    I am placing an order from SORT IT on ${new Date().toLocaleDateString()}.

    The order can be found in attached .csv.

    Please could you get back to me with delivery
    quotes and any further information.

    Thanks in advance,

    ${name}
    `

    const mockRequest = () => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ message: "Email has been sent successfully!" })
        }, 3500)
      })
    }
   
    const submitOrder = async () => {
      if (isChecked) {
        resetForm()
        const toastId = toast.loading("Sending Email...", {
          pauseOnFocusLoss: false,
        })
        try {
          await mockRequest()
          toast.dismiss(toastId)
          toast.success("Email has been sent successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnFocusLoss: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          })
        } catch (err) {
          toast.dismiss(toastId)
          toast.error("There was an error!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnFocusLoss: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          })
        }
      } else {
        setValidationError(true)
      }
    }

  return (
    <div className="d-flex flex-column">
        <p className={styles.subject}>
            <span className="font-weight-bold">Subject:</span>{" "}
            <span className={styles.darkGray}>{subject}</span>
        </p>
      <p>
        <span className="font-weight-bold">From:</span> {" "}
        <span className={styles.darkGray}>{SORT_IT_EMAIL}</span>
      </p>
      <p>
        <span className="font-weight-bold">To:</span>{" "}
        <span className={styles.toEmails}>{merchantEmail}; <span className={styles.darkGray}>{email}</span></span>
      </p>
      <Form>
        <Form.Group className="m-0">
          <Form.Label>
            <span className="font-weight-bold">CC:</span> {" "}
            <span className={styles.darkGray}>{SORT_IT_EMAIL}</span>
          </Form.Label>
          <div className={styles.attachementContainer}>
            <CSVLink
              data={[csvHeadings, ...buildDataForCSV(cheapestItems)]}
              filename={csvFilename}
              onClick={() => setCsvFilename(formatFilename)}
            >
              <Button
                variant="outline-dark"
                className={styles.attachementButton}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="20px"
                  width="25px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke-width="2"
                    d="M22,12 C22,12 19.0000009,15.0000004 13.0000004,21.0000004 C6.99999996,27.0000004 -2.00000007,18.0000004 3.99999994,12.0000004 C9.99999996,6.00000037 9,7.00000011 13,3.00000008 C17,-0.999999955 23,4.99999994 19,9.00000005 C15,13.0000002 12.0000004,16.0000007 9.99999995,18.0000004 C7.99999952,20 5,17 6.99999995,15.0000004 C8.99999991,13.0000007 16,6 16,6"
                  ></path>
                </svg>
              </Button>
            </CSVLink>
          </div>
          <Form.Control value={customerMessage} as="textarea" rows={14} />
          <div className="d-flex align-items-baseline mt-3">
            <p 
             className="p-0 my-0 mr-3 " 
             onClick={() => setTermsAndConditionOpen((prev => !prev))}
             >
              I agree to the {" "}
              <Button variant="link" className="p-0 m-0 align-baseline">Terms & Conditions</Button>
            </p>
            <Form.Check 
              checked={isChecked} 
              onChange={({ target: { checked } }) => setChecked(checked)} 
              type="checkbox" />
          </div>
          {validationError && <Alert className="mt-3 mb-0" variant="danger">You have to agree with Terms & Conditions before submitting!</Alert>}
        </Form.Group>
      </Form>
      <Row>
      <Col className="mb-2">
        <Toast 
          className="mw-100"
          show={isTermsAndConditionOpen} 
          onClose={() => setTermsAndConditionOpen(false)} 
          >
          <Toast.Header className="justify-content-between">
            Terms & Conditions
          </Toast.Header>
          <Toast.Body>
            <ul className="p-3 text-secondary">
              <li className="mb-4"> If You wish to place an Order for Products available on the Service, You may be asked to supply certain
    information relevant to Your Order including, without limitation, Your name, Your email, Your phone number,
    Your credit card number, the expiration date of Your credit card, Your billing address, and Your shipping
    information.</li>
              <li className="mb-4"> You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other
    payment method(s) in connection with any Order; and that (ii) the information You supply to us is true,
    correct and complete.</li>
              <li> By submitting such information, You grant us the right to provide the information to payment processing
    third parties for purposes of facilitating the completion of Your Order.</li>
            </ul>
          </Toast.Body>
        </Toast>
      </Col>
    </Row>
    <div className={styles.footerContainer}>
      <Button
        variant="secondary"
        onClick={resetForm}
        >
        Close
      </Button>
      <Button variant="primary" type="submit" onClick={submitOrder}>
        Send
      </Button>
      </div>
    </div>
  )
}

export default Order
