import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { What3wordsMap } from "@what3words/react-components"
import DateTimeInput from "../DateTimeInput"
import styles from "../../OrderModal.module.scss"
import { WithDeliveryProps } from "../../../MerchantAccordionProps"

const API_KEY = "IPRKSJK2"
const MAP_API_KEY = "AIzaSyA5F6RN_yrV5QH3UzsGCET4ORHNJEwPUBA"

const WithDelivery = ({
  isAddressSame,
  setAddressSame,
  projectAddress,
  validated,
  orderRecipientAddress,
  setOrderRecipientAdress,
  setDeliveryDateAndTime,
}: WithDeliveryProps) => {
  const [showMap, setShowMap] = useState(true)

  return (
    <div className="mt-3">
      <span className="font-weight-bold">
        Is delivery address same as project address?
      </span>
      <div className={styles.deliveryContainer}>
        <div className={styles.deliveryCheckbox}>
          <label>Yes</label>
          <input
            type="checkbox"
            checked={isAddressSame === 1}
            onChange={() => setAddressSame(1)}
          />
        </div>
        <div className={styles.deliveryCheckbox}>
          <label>No</label>
          <input
            type="checkbox"
            checked={isAddressSame === 0}
            onChange={() => setAddressSame(0)}
          />
        </div>
      </div>
      <Form.Group className="mt-2">
        <Form.Control
          isInvalid={
            validated &&
            !(isAddressSame ? projectAddress : orderRecipientAddress)
          }
          onChange={({ currentTarget: { value } }) =>
            setOrderRecipientAdress(value)
          }
          type="text"
          disabled={!!isAddressSame}
          placeholder="Order Recipient Address"
          value={isAddressSame ? projectAddress : orderRecipientAddress}
          required
        />
        <Form.Control.Feedback type="invalid">
          Please enter an order recipient name.
        </Form.Control.Feedback>
      </Form.Group>
      <DateTimeInput validated={validated} setTime={setDeliveryDateAndTime} />
      <p className="font-weight-bold mt-4">Do you know where delivery should go?</p>
      <div className={styles.deliveryContainer}>
        <div className={styles.deliveryCheckbox}>
          <label>Yes</label>
          <input
            type="checkbox"
            checked={!!showMap}
            onChange={() => setShowMap(true)}
          />
        </div>
        <div className={styles.deliveryCheckbox}>
          <label>No</label>
          <input
            type="checkbox"
            checked={!showMap}
            onChange={() => setShowMap(false)}
          />
        </div>
      </div>
      {showMap && (
        <div className="my-2">
          <p className="font-weight-bold">
            If you know where the delivery should go, please select the relevant
            what3words square
          </p>
          <What3wordsMap
            api_key={API_KEY}
            map_api_key={MAP_API_KEY}
            zoom={5}
            selected_zoom={5}
            lng={-0.114637}
            lat={51.454843}
            search_control_position={2}
            onChange={e => console.log(e)}
            map_type_control={true}
            zoom_control={true}
            fullscreen_control={true}
            fullscreen_control_position={3}
            current_location_control_position={9}
            disable_default_ui={true}
            map_type_id="satellite"
            words="///medium.rash.sing"
          >
            <div className={styles.what3WordsMap} slot="map" />
          </What3wordsMap>
        </div>
      )}
      <p className="font-weight-bold mt-4">Delivery notes:</p>
      <Form.Group>
        <Form.Control
          placeholder="Add any additional that could be helpful here"
          as="textarea"
          rows={5}
        />
      </Form.Group>
    </div>
  )
}

export default WithDelivery
