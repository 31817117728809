import React, { useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { CreateProjectModalProps } from "../MerchantAccordionProps"
import FormInput from "./FormInput"
import SingleDropdown from "./SingleDropdown"
import Dropdown from "react-bootstrap/Dropdown"
import styles from "./CreateProjectModal.module.scss";
import DateTimeInput from "./DateTimeInput"
import ProjectDuration from "./ProjectDuration"

const PROJECT_TYPE_OPTIONS = ["New build", "Refurb", "Both"]

const PROJECT_SCOPE_OPTIONS = ["Partial", "Total"]

const PROJECT_SUBTYPE_OPTIONS = [
    {
      label: "Carpentry (Cosmetic/ Architectural)",
      value: "Carpentry (Cosmetic/ Architectural)",
    },
    { label: "Carpentry (Structural)", value: "Carpentry (Structural)" },
    {
      label: "Blockwork (Cosmetic/ architectural)",
      value: "Blockwork (Cosmetic/ architectural)",
    },
    { label: "Blockwork (Structural)", value: "Blockwork (Structural)" },
    { label: "Civil", value: "Civil" },
    { label: "Electrical", value: "Electrical" },
    { label: "Heating", value: "Heating" },
    { label: "Landscaping", value: "Landscaping" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Structural", value: "Structural" },
  ]

const STRUCTURE_TYPE_OPTIONS = ["Substructure", "Superstructure", "Both"];
const LISTED_STATUS_OPTIONS = ["Grade I", "Grade II", "Unlisted"]
const DEMOLITION_ACTIVITIES_OPTIONS = ["Yes", "No"]
const PROJECT_EXTENT_OPTIONS = ["Interior", "Exterior", "Both"]
const SITE_KNOWLEDGE_OPTIONS = ["Not visited site", "Site visited"]

const CONTRACT_TYPE_OPTIONS = [
    "Standard Building Contract",
    "Intermediate Building Contract",
    "Minor Works Building Contract",
    "Major Project Construction Contract",
    "Design and Build Contract",
    "Management Building Contract",
    "Construction Management Contract",
    "JCT-CE Contract",
    "Measured Term Contract",
    "Prime Cost Building Contract",
    "Repair & Maintenance Contract",
  ]

const CONTRACT_RESPONSIBILITIES_OPTIONS = ["Main Contractor", "Sub Contractor"]

const CreateProjectModal = ({ isCreateProjectModalOpen, setCreateProjectModalOpen }: CreateProjectModalProps) => {
 const [projectName, setProjectName] = useState("");
 const [projectDescription, setProjectDescription] = useState("");
 const [clientName, setClientName] = useState("");
 const [projectTypeOption, setProjectTypeOption] = useState("")
 const [selectedOptions, setSelectedOptions] = useState([])
 const [projectScopeOption, setProjectScopeOption] = useState("")
 const [structureType, setStructureType] = useState("")
 const [listedStatus, setListedStatus] = useState("")
 const [demolitionActivity, setDemolitionActivity] = useState("")
 const [projectExtent, setProjectExtent] = useState("")
 const [siteKnowledge, setSiteKnowledge] = useState("")
 const [date, setDate] = useState({ day: "", month: "", year: "" });
const [duration, setDuration] = useState({ days: '', weeks: '', months: '' });
const [contractType, setContractType] = useState("");
const [contractResponsibilities, setContractResponsibilities] = useState("")
const [isValidationTriggered, setValidationTriggered] = useState(false);

 const handleSelect = value => {
   if (selectedOptions.includes(value)) {
     setSelectedOptions(selectedOptions.filter(option => option !== value))
   } else {
     setSelectedOptions([...selectedOptions, value])
   }
 }

 const resetForm = () => {
    setProjectName("");
    setProjectDescription("");
    setClientName("");
    setProjectTypeOption("")
    setSelectedOptions([]);
    setProjectScopeOption("");
    setStructureType("");
    setListedStatus("");
    setDemolitionActivity("");
    setProjectExtent("");
    setSiteKnowledge("");
    setDate({ day: "", month: "", year: "" });
    setDuration({ days: "", weeks: "", months: "" });
    setContractType("");
    setContractResponsibilities("");
    setCreateProjectModalOpen(false);
    setValidationTriggered(false);
 }

 const mockRequest = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ message: "Project has been created successfully!" })
      }, 3500)
    })
  }
 
  const submitProjectCreation = async () => {
    resetForm();
    const toastId = toast.loading("Creating a project...", {
      pauseOnFocusLoss: false,
    })
      try {
        await mockRequest()
        toast.dismiss(toastId)
        toast.success("Project has been created successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnFocusLoss: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        })
      } catch (err) {
        toast.dismiss(toastId)
        toast.error("There was an error!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnFocusLoss: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        })
      }
    } 

    const handleSubmit = event => {
        event.preventDefault()
        const isValid =
          event.currentTarget.checkValidity() &&
          projectName &&
          clientName &&
          projectTypeOption &&
          projectScopeOption &&
          selectedOptions.length > 0 &&
          Object.values(date).every((item) => !!item.length) &&
          Object.values(duration).find((item) => !!item.length)
         

        setValidationTriggered(true)
        if (isValid) {
            submitProjectCreation();
        }
      }

  return (
    <>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
      />
      <Modal
        show={isCreateProjectModalOpen}
        onHide={() => resetForm()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create project</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form className="d-flex flex-column" noValidate onSubmit={handleSubmit}>
            <FormInput
              value={projectName}
              setter={setProjectName}
              placeholder="Project Name"
              isRequired
              isValidationTriggered={isValidationTriggered}
              errorMessage="Please enter a project name."
            />
            <FormInput
              value={projectDescription}
              setter={setProjectDescription}
              placeholder="Project Description"
            />
            <FormInput
              value={clientName}
              setter={setClientName}
              placeholder="Client Name/Company Name"
              isRequired
              isValidationTriggered={isValidationTriggered}
              errorMessage="Please enter a client/company name."
            />
            <SingleDropdown
              value={projectTypeOption}
              setter={setProjectTypeOption}
              data={PROJECT_TYPE_OPTIONS}
              defaultValue="Project Type"
              isValidationTriggered={isValidationTriggered}
              errorMessage="Please select a project type."
            />
        <Form.Group className="mb-6">
          <Dropdown aria-required>
            <Dropdown.Toggle
              className={styles.dropdown}
              variant="outline-secondary"
            >
              <span>Project Subtype</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100 d-flex flex-column align-items-start p-3">
              {PROJECT_SUBTYPE_OPTIONS.map(({ label, value }) => (
                <Form.Check
                  key={value}
                  type="checkbox"
                  className="mb-2"
                  label={label}
                  value={value}
                  checked={selectedOptions.includes(value)}
                  onChange={() => handleSelect(value)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {isValidationTriggered && !selectedOptions.length && (
            <div className="invalid-feedback d-block">
              Please select at least one project subtype.
            </div>
          )}
        </Form.Group>
        <SingleDropdown
          value={projectScopeOption}
          setter={setProjectScopeOption}
          data={PROJECT_SCOPE_OPTIONS}
          defaultValue="Project Scope"
          isValidationTriggered={isValidationTriggered}
          errorMessage="Please select a project scope."
        />
        <SingleDropdown
          value={structureType}
          setter={setStructureType}
          data={STRUCTURE_TYPE_OPTIONS}
          isRequired={false}
          defaultValue="Structure type"
        />
        <SingleDropdown
          value={listedStatus}
          setter={setListedStatus}
          data={LISTED_STATUS_OPTIONS}
          isRequired={false}
          defaultValue="Listed status"
        />
        <SingleDropdown
          value={demolitionActivity}
          setter={setDemolitionActivity}
          data={DEMOLITION_ACTIVITIES_OPTIONS}
          isRequired={false}
          defaultValue="Demolition Activities"
        />
        <SingleDropdown
          value={projectExtent}
          setter={setProjectExtent}
          data={PROJECT_EXTENT_OPTIONS}
          isRequired={false}
          defaultValue="Project Extent"
        />
        <SingleDropdown
          value={siteKnowledge}
          setter={setSiteKnowledge}
          data={SITE_KNOWLEDGE_OPTIONS}
          isRequired={false}
          isValidationTriggered={isValidationTriggered}
          defaultValue="Site knowledge"
        />
        <DateTimeInput date={date} setDate={setDate} isValidationTriggered={isValidationTriggered} />
        <ProjectDuration duration={duration} setDuration={setDuration} isValidationTriggered={isValidationTriggered} />
        <SingleDropdown
          value={contractType}
          setter={setContractType}
          data={CONTRACT_TYPE_OPTIONS}
          isRequired={false}
          defaultValue="Contract Type"
        />
        <SingleDropdown
          value={contractResponsibilities}
          setter={setContractResponsibilities}
          data={CONTRACT_RESPONSIBILITIES_OPTIONS}
          isRequired={false}
          defaultValue="Contract Responsibilities"
        />
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => resetForm()}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>Create Project</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default CreateProjectModal
