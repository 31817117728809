import React from "react"
import { Form } from "react-bootstrap"
import Dropdown from "react-bootstrap/Dropdown"
import styles from "../CreateProjectModal.module.scss";
import { SingleDropdownProps } from "../../MerchantAccordionProps";

const SingleDropdown = ({ value, setter, data, defaultValue, isValidationTriggered, isRequired = true, errorMessage }: SingleDropdownProps) => {
  return (
    <Form.Group className="mb-6">
      <Dropdown aria-required={isRequired} onSelect={eventKey => setter(eventKey)}>
        <Dropdown.Toggle
          variant="outline-secondary"
          className={`${styles.dropdown} ${
            isValidationTriggered && !value ? "is-invalid" : ""
          }`}
        >
          {value || defaultValue}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100">
          {data.map(option => (
            <Dropdown.Item key={option} eventKey={option}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {isRequired && (isValidationTriggered && !value && (
        <div className="invalid-feedback d-block">
          {errorMessage}
        </div>
      ))}
    </Form.Group>
  )
}

export default SingleDropdown
